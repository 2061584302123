
import * as strapiRuntime$n79qD8uQf338CYG4RQwDE6rCH_45VWWyQ_45M3_45H9rDap04 from '/app/node_modules/.pnpm/@nuxt+image@1.10.0_@capacitor+preferences@6.0.3_@capacitor+core@7.1.0__db0@0.3.1_idb-keyval@6_nsg2jyqylqbcu45kgwox2e4rfu/node_modules/@nuxt/image/dist/runtime/providers/strapi'
import * as belongRuntime$SqKuZtrwoACNXnqD9XXrClJfRn88rzdf1fC3PPAZI3Q from '/app/apps/website/app/providers/s3-belong.ts'
import * as ipxRuntime$xX4gyZO7yVQ36lcL0soa9MjVzSFZsV2CRsO4aVCuTZA from '/app/node_modules/.pnpm/@nuxt+image@1.10.0_@capacitor+preferences@6.0.3_@capacitor+core@7.1.0__db0@0.3.1_idb-keyval@6_nsg2jyqylqbcu45kgwox2e4rfu/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['strapi']: { provider: strapiRuntime$n79qD8uQf338CYG4RQwDE6rCH_45VWWyQ_45M3_45H9rDap04, defaults: undefined },
  ['belong']: { provider: belongRuntime$SqKuZtrwoACNXnqD9XXrClJfRn88rzdf1fC3PPAZI3Q, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$xX4gyZO7yVQ36lcL0soa9MjVzSFZsV2CRsO4aVCuTZA, defaults: {} }
}
        
import { joinURL, parseHost } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'

import { createOperationsGenerator } from '#image'

const operationsGenerator = createOperationsGenerator()

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL } = {}
) => {
  const operations = operationsGenerator(modifiers)

  // console.log(src, src.startsWith('http'))

  // // 1. is external http
  // if (src.startsWith('http')) {
  //   return {
  //     url: src + operations,
  //   }
  // }

  // 2. is belong
  if (!baseURL) {
    baseURL = useRuntimeConfig().public.appLink
  }
  const path = src + (operations ? '?' + operations : '')

  return {
    url: joinURL(baseURL, path),
  }
}
